.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.field:not(:last-child) {
  margin-bottom: 1rem !important;
}

.field ion-label {
  padding-left: 0.2rem;
  padding-right: 0.5rem;
  color: #d3a6c7;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.field p {
  color: rgb(236, 149, 35);
}
.customInput {
  --background: #834e76;
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 10px;
  margin-top: 0.25rem;
  transition: all 0.2s linear;
}
