.app-navigation-menu {
  padding-top: 30px;
  .app-navigation-title {
    padding-left: 30px;
    margin-bottom: 20px;
  }
  .app-navigation-menu-item {
    padding: 8px 25px;
    border-bottom: 1px solid $grey-light-plus;
    &.first {
      border-top: 1px solid $grey-light-plus;
    }
    &.app-navigation-menu-item-open {
      background-color: $white;
      .app-navigation-menu-item__label {
        color: $primary;
        font-weight: 600;
      }
      .app-navigation-menu-item__icon {
        svg {
          fill: $primary;
          path {
            fill: $primary !important;
          }
        }
      }
      .app-navigation-menu-item-arrow {
        transition: ease-in-out all 0.3s;
        transform: rotate(-180deg);
      }
    }
    a {
      text-decoration: none;
      color: $black-light;
    }
    .app-navigation-menu-item__wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
    }
    .app-navigation-menu-item__icon {
      svg {
        width: 35px;
      }
    }
    .app-navigation-menu-item__label {
      font-weight: 500;
      font-size: 1.2rem;
      font-family: $font-family-main;
    }
    .app-navigation-menu-item-arrow {
      position: absolute;
      right: 30px;
      transition: ease-in-out all 0.3s;
      transform: rotate(0);
      svg {
        width: 20px;
      }
    }
    .app-navigation-menu-item__child {
      margin-left: 55px;
      font-size: 1rem;
      padding: 10px 0;
      &:last-child {
        padding-bottom: 15px;
      }
      .app-navigation-menu-item__label {
        //font-size: 1rem !important;
        font-weight: 500 !important;
      }
    }
  }
}

ion-tab-bar {
  &.app-navigation {
    height: 70px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 2px rgb(0, 0, 0, 0.15);
    .app-navigation-icon {
      margin-bottom: 3px;
      svg {
        width: 30px;
      }
    }
    ion-tab-button {
      background: $white;
      ion-label {
        font-weight: 500;
        font-size: 0.9rem;
        margin-top: -3px;
        color: #151415;
      }
    }
    ion-tab-button.tab-selected {
      ion-label {
        color: #00591d;
      }
      .app-navigation-icon {
        svg {
          fill: #00591d !important;
          path {
            fill: #00591d !important;
          }
        }
      }
    }
  }
}