:root {
  --ion-font-family: $font-family-main;
  --ion-color-primary: $primary;
  --ion-color-dark: $black-light;
  --ion-color-base: $primary;
}

body {
  font-family: $font-family-main;
}
p {
  margin-top: 0;
}
ion-app {
  font-family: $font-family-main;
}