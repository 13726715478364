// Fonts
//$font-family-main: "Config";
$font-family-main: 'Barlow', sans-serif;
$font-family-heading: "Moranga";
// Colors
$black-light: #161615;
$white: #ffffff;
$primary: #00591d;
$primary-light: #B3D1B3;
$secondary: #f2ac28;
$grey: #666;
$grey-light: #f5f5f5;
$grey-light-plus: #dedede;
$carbon-grey: #3c434a;
$red: #c0392b;

$colors: (
	white: #fff,
);