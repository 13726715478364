button {
    font-family: $font-family-main;
    &.btn {
      width: 100%;
      border-radius: 8px !important;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 20px;
      &.btn-colored {
        background: $primary;
        border: 2px solid $primary;
        color: $white;
        &-white {
          background-color: $white;
          color: $primary;
          border-color: $primary;
        }
        &.btn-colored-danger {
          background-color: $red;
          color: $white;
          border-color: $red;
        }
        svg {
          fill: $white;
        }
        &.btn-loading {
          height: 70px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          ion-spinner {
            color: $white
          }
        }
      }
      &.btn-simple {
        background: none;
        padding: 0;
        color: #666;
      }
      &-with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.btn-outline {
        background-color: transparent;
        border: 2px solid $primary;
        color: $primary;
        &.btn-outline-grey {
          border-color: $grey-light-plus;
          color: $grey;
        }
        &.btn-outline-red {
          border-color: $red;
          color: $red;
        }
      }
    }
  }