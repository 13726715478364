.app-page-wrapper {
    padding: 100px 0 60px;
    &.app-page-menu {
        padding-top: 20px;
    }
    .app-page-content {
        padding: 0 20px;
    }
    &-login-routes {
        padding: 0;
    }
}

.align-right {
    display: flex;
    justify-content: flex-end;
}