ion-row.weather-data {
    font-family: "ConfigFont";
    font-size: 12px;
    width: auto;
    text-align: center;
}

.active {
    background: #5192ec75;
}
