.app-headline {
    text-align: center;
    h1 {
        text-align: center;
        font-size: 2rem;
        line-height: 1.2;
    }
    p {
        text-align: center;
        font-size: 1.3rem;
        line-height: 1.2;
    }
}
