.protected-page {
  padding: 20px;
  .protected-page-header {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 20px 0 15px;
    .app-back-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      // background: $white;
      // border-radius: 100%;
      // box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
      margin-left: 0;
      svg {
        width: 9px;
      }
    }
    .protected-page-logo {
      text-align: center;
      width: calc(100% - 40px);
      position: relative;
      left: -20px;
      svg {
        width: 180px;
      }
    }
  }
  &-headline {
    h1 {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }
  &__content {
    text-align: center;
    font-size: 1.3em;
    font-family: $font-family-main;
    line-height: 1.4;
  }
  &__force-login {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 50px;
  }
}