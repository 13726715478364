.hero-gallery-room {
    position: relative;
    height: 400px;
    .gallery-room-main {
        position: relative;
        height: 400px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        z-index: 2;
        img {
            position: absolute;
            top: 0;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }
    .room-hero {
        position: absolute;
        bottom: 18px;
        z-index: 4;
        max-width: 80%;
        margin: 0 auto;
        transform: translateX(-50%);
        left: 50%;
        .room-hero-details {
            display: flex;
            justify-content: center;
            gap: 20px;
            .label {
                font-size: 1.3rem;
                font-weight: 600;
                color: $white;
            }
        }
        .room-hero-title {
            text-align: center;
            margin-bottom: 25px;
            h1 {
                font-weight: 600;
                color: $white;
                font-size: 2.3rem;
                margin-top: 5px;
            }
        }
        .gallery-room-thumbs-container {
            .swiper-wrapper {
                justify-content: center;
            }
            .swiper-slide {
                max-width: 60px;
            }
            .thumbs-item {
                border-radius: 8px;
                border: 3px solid $white;
                max-width: 60px;
                img {
                    border-radius: 4px;
                }
            }
        } 
    }
    .hero-room-overlay {
        position: absolute;
        z-index: 3;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.4444371499) 99%, rgba(0, 0, 0, 0.6041010154) 100%);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}

.room-details {
    .room-services {
        padding: 20px 25px;
        .headline {
            margin-bottom: 10px;
            p {
                font-weight: 500;
                font-size: 1.2rem;
                margin-bottom: 0;
            }
        }
        .room-services-list {
            gap: 15px;
            display: flex;
            overflow-x: scroll;
            width: calc(100% + 50px);
            left: -25px;
            position: relative;
            padding-left: 20px;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            .room-services-item {
                display: flex;
                padding: 15px;
                background: $white;
                min-width: 180px;
                border-radius: 4px;
                font-size: 1.2rem;
                white-space: nowrap;

            }
        }
    }
    .room-description {
        padding: 20px 25px;
        padding-top: 0;
        .headline {
            margin-bottom: 10px;
            p {
                font-weight: 500;
                font-size: 1.2rem;
                margin-bottom: 0;
            }
        }
        .room-description-content {
            padding: 15px;
            background: $white;
            font-size: 1.2rem;
            line-height: 1.4;
        }
    }
}


.page-content-loader {
    padding-top: 70px;
    .page-content-loader-icon {
        max-width: 60%;
        margin: 0 auto;
    }
    .page-content-loader-text {
        max-width: 80%;
        margin: 0 auto;
        margin-top: -30px;
        text-align: center;
    }
}