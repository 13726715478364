.main-header-card {
    height: 360px;
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    &-image {
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
        }
        ion-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            img {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
    &-title {
      position: absolute;
      bottom: 35px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
        h1,
        h3 {
            color: $white;
            margin: 0;
        }
        h3 {
            font-size: 1.8rem;
            margin-bottom: 3px;
        }
        h1 {
            font-size: 2.3rem;
            margin-bottom: 0;
        }
    }
    &-overlay {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.444437149859944) 35%, rgba(0,0,0,0.6041010154061625) 100%);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}