.room-card {
    padding: 20px 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 8px 4px rgb(0 0 0 / 10%);
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
    .room-card-image {
        height: 130px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
        }
    }
    .room-card-details {
        padding-left: 4px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        .room-card-name {
            h3 {
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
        .room-card-info {
            display: flex;
            font-weight: 500;
            color: #7C8B95;
            font-size: 0.9rem;
            
        }
        .divider {
            margin: 0 6px;
        }
        h5 {
            margin-top: 0;
        }
    }
    &-price {
        .starting-from {
            font-weight: 600;
            color: $grey;
            p {
                margin: 0;
            }
        }
        h4 {
            color: $primary;
            font-size: 1.1rem;
            font-weight: 600;
            margin: 0;
        }
    }
}