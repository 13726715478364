.app-block {
  &-title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 2px;
    font-family: $font-family-main;
  }
  &-content {
    background-color: $white;
    padding: 30px;
  }
}