.app-blog-page {
    background: #ffff;
    border-radius: 8px;
    padding: 30px 25px;
    .app-blog-title {
        margin-bottom: 25px;
        h4 {
            margin: 0;
        }
    }
    .app-blog-item {
        max-width: 100%;
        flex: 0 0 100%;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $grey-light-plus;
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .app-blog-item-wrapper {
            display: flex;
            flex-wrap: wrap;
            .app-blog-image {
                max-width: 35%;
                flex: 0 0 35%;
   
                img {
                    border-radius: 15px;
                }
             
            }
            .app-blog-content {
                max-width: 65%;
                flex: 0 0 65%;
                padding-left: 20px;
                .app-blog-content-title {
                    margin-bottom: 5px;
                    h3 {
                        font-size: 1.1rem;
                        color: $primary;
                        margin: 0;
                    }
                }
                .app-blog-content-excerpt {
                    color: $grey;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2; 
                    -webkit-box-orient: vertical;
                }
            }
        }   
    }
}