.restaurant-menu {
    position: relative;
    .resturant-menu-block {
        .title {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            .icon {
                svg {
                    width: 40px;
                    height: 40px;
                }
            }
            .label {
                font-weight: 500;
                font-size: 1.2rem;
            }
        }
        .restaurant-menu-list {
            .restaurant-menu-list-item{
                padding: 15px;
                background: #fff;
                border-radius: 4px;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                .product-details {
                    max-width: 70%;
                    flex: 0 0 70%;
                    .name {
                        margin-bottom: 6px;
                        h5 {
                            font-size: 1.3rem; 
                            font-weight: 600;
                            margin: 0;
                        }
                    }
                    .description {
                        color: $grey;
                    }
                }
                .product-price {
                    max-width: 30%;
                    flex: 0 0 30%;
                    display: flex;
                    justify-content: flex-end;
                    span {  
                        font-family: $font-family-heading;
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: $primary;
                    }
                }
                .product-allergens {
                    display: flex;
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-top: 10px;
                    gap: 10px;
                    align-items: center;
                    .label {
                        font-weight: 500;
                        font-size: 1rem;
                    }
                    .icons {
                        display: flex;
                        gap: 10px;
                        .icon {
                            display: flex;
                            align-items: center;
                            img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .restaurant-menu-category {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        .restaurant-menu-category-item-top {
            max-width: 50%;
            flex: 0 0 50%;
            padding: 0 8px;
        }
    }
    a {
        text-decoration: none;
    }
    .restaurant-menu-category-item {
        margin-bottom: 20px;
        padding: 10px 10px 20px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #dedede;
        &:last-child() {
            margin-bottom: 0;
        }
        .restaurant-menu-category-item-wrapper {
            .restaurant-menu-category-item-overlay {
                position: absolute;
                z-index: 3;
                top: 0;
                height: 100%;
                width: 100%;
                background: rgb(0, 0, 0);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.4444371499) 99%, rgba(0, 0, 0, 0.6041010154) 100%);
                border-radius: 30px;
            }
            .restaurant-menu-category-item-image {
                position: relative;
                height: 170px;
                border-radius: 12px;
            }
            img {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 12px;
            }
            .restaurant-menu-category-item-title {
                text-align: center;
                h3 {
                    margin: 0;
                    font-size: 1.1rem;
                    margin-top: 20px;
                }
            }
        }
    }
}

.app-restaurant-anchors {
    display: flex;
    gap: 10px;
    padding: 0 15px;
    &.app-footer-menu-restaurant {

    }
    .btn-icon {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px;
        .icon {
            display: flex;
            align-items: center;
            svg {
                width: 30px;
            }  
        }   
        .label {
            font-weight: 500;
        }   
    }
}

ion-footer {
    &.app-footer-menu-restaurant {
        ion-toolbar {
            padding: 35px 0 8px 0;
        }
        .title {
            text-align: center;
            h3 {
                margin: 0;
                margin-bottom: 15px;
            }
        }
    }
}