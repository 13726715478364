.form-social-login {
    text-align: center;
    margin-top: 30px;
    .form-social-login-title {
        p {
            margin: 0;
            font-weight: 500;
            font-size: 1.2rem;
            margin-bottom: 5px;
        }
    }
}