.services-block {
    padding: 0 20px;
    .services-title {
        margin-bottom: 20px;
        h3 {
            font-family: $font-family-main !important;
            font-size: 1.2rem !important;
            font-weight: 600;
            font-weight: 500;
            margin-bottom: 10px !important;
        }
    }
    
    
}
.services-block-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .services-block-cards-item  {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 0 10px;
        a {
            text-decoration: none;
        }
    }
    .services-block-cards-item-wrapper {
        border-radius: 6px;
        padding: 40px 20px;
        text-align: center;
        &.service-block-cards-gradient-1 {
            background-color: $primary-light;
            .services-block-cards-item-icon {
                svg {
                    fill: $primary !important;
                    path {
                        fill: $primary !important;
                    }
                }
            }
            .services-block-cards-item-content {
                h3 {
                    color: $primary;
                }
            }
        }
        &.service-block-cards-gradient-2 {
            background-color: $primary;
            .services-block-cards-item-icon {
                svg {
                    fill: $primary-light !important;
                    path {
                        fill: $primary-light !important;
                    }
                }
            }
            .services-block-cards-item-content {
                h3 {
                    color: $primary-light;
                }
            }
        }
        .services-block-cards-item-icon {
            svg {
                width: 50px;
            }
        }
        .services-block-cards-item-content {
            margin-top: 8px;
            h3 {
                font-weight: 600;
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }
}