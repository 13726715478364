ion-header {
    position: absolute;
    height: 70px;
    padding: 0 15px 0 20px;
    ion-toolbar {
      position: absolute;
      height: 70px;
      --background: transparent;
      --ion-color-base: transparent !important;
      width: 100%;
      left: 0;
      .toolbar-background {
        background-color: transparent !important;
        --background-color: transparent;
        --ion-color-base: transparent !important;
      }
      .no-bg {
        background-color: transparent !important;
      }
      ion-title {
        font-family: $font-family-heading;
        color: $white;
      }
    }
    .toolbar-background {
      background-color: transparent;
    }
    &.app-header {
        .app-header-wrapper {
            position: relative;
        }
        ion-title {
            padding: 0;
            text-align: left;
        }
    }
    &.app-header-menu {
      ion-toolbar {
        border-bottom: 0;
        .toolbar-background {
          border-bottom: 0;
        }
      }
    }
    ion-menu-button {
      height: 70px;
      width: 70px;
      .hamburger-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: $white;
        border-radius: 100%;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
        label {
          display: flex;
          flex-direction: column;
          width: 25px;
          cursor: pointer;
          span {
            background: $black-light;
            border-radius: 10px;
            height: 2px;
            margin: 3px 0;
            transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
            &:nth-of-type(1) {
              width: 100%;
            }
            &:nth-of-type(2) {
              width: 75%;
            }
            &:nth-of-type(3) {
              width: 50%;
            }
          }
          input[type="checkbox"] {
            display: none;
            &:checked ~ span:nth-of-type(1) {
              transform-origin: bottom;
              transform: rotatez(45deg) translate(5px, 6px);
            }
            &:checked ~ span:nth-of-type(2) {
              transform-origin: top;
              transform: rotatez(-45deg);
              width: 100%;
            }
            &:checked ~ span:nth-of-type(3) {
              transform-origin: bottom;
              width: 50%;
              transform: translate(9px, -4.5px) rotatez(45deg);
            }
          }
        }
      }
    }
    &.app-header-back {
      background-color: $white;
      border-bottom: 1px solid $grey-light-plus;
      .toolbar-background {
        background-color: $white;
      }
      ion-toolbar {
        background-color: $white;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey-light-plus;
        ion-title {
          color: $black-light;
          padding: 0 40px;
          text-align: center;
          font-size: 1.4rem;
        }
        .app-header-title {
          width: 80%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-weight: 600;
          font-family: $font-family-heading;
          font-size: 1.4rem;
          text-align: center;
        }
        .back-button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 40px;
          height: 40px;
          // background: $white;
          // border-radius: 100%;
          // box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
          margin-left: 0;
          &.transparent {
            svg {
              path {
                fill: $white !important;
              }
            }
          }
          svg {
            width: 9px;
          }
        }
      }
      &.app-header-type-transparent {
        background-color: transparent;
        border: 0;
        .toolbar-background {
          background-color: transparent;
        }
        ion-toolbar {
          background-color: transparent;
          border: 0;
        }
      }
    }
    &.app-header-with-logo {
      text-align: center;
      width: calc(100% - 100px);
      margin: 0 auto;
      svg {
        width: 180px;
      }
    }
    .notify-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        min-width: 40px !important;
        height: 40px;
        background: $white;
        border-radius: 100%;
        box-shadow: 0px 0px 4px 2px rgb(0,0,0,0.2);
        margin-left: 10px;
        margin-right: -10px;
        svg {
            width: 25px;
        }
    }
}

ion-header .toolbar-background {
    background-color: $grey-light;
}
.app-header-menu {
  padding-top: 15px;
  ion-menu-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px !important;
    height: 40px;
    // background: #ffffff;
    // border-radius: 100%;
    // box-shadow: 0px 0px 4px 2px rgb(0,0,0,0.2);
    margin-left: 10px;
    margin-right: 20px;
    svg {
      width: 15px;
    }
  }
}


.app-small-header {
  padding: 20px;
  &-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  .app-back-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    // background: $white;
    // border-radius: 100%;
    // box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    margin-left: 0;
    svg {
      width: 9px;
    }
  }
  &-logo {
    text-align: center;
    width: 100%;
    svg {
      width: 180px;
    }
  }
}