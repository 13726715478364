
.app-faq-page {
  background: $white;
  border-radius: 8px;
  padding: 30px 25px;
  .app-faq-title {
    margin-bottom: 25px;
    h4 {
      line-height: 1.5;
      margin: 0;
      color: $primary;
    }
  }
  .app-faq {
    ion-accordion-group {
      margin: 0;
    }
    .app-faq-item {
      margin-bottom: 20px;
      border-radius: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      &.accordion-expanded {
        background-color: $primary-light;
        ion-icon {
          color: $white;
        }
        .app-faq-item-header {
          .app-faq-item-header-content {
            color: $white;
          }
        }
        .app-faq-item-content { 
          color: $primary;
          font-weight: 500;
        }
      }
      ion-icon {
        font-size: 1.2rem;
        margin-top: 18px;
      }
      ion-item::part(native) {
        background-color: transparent !important;
        border: 0;
      }
      .app-faq-item-header {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .app-faq-item-header-content {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.3;
          padding: 15px 10px 10px 0;
          font-family: $font-family-heading;
        }
      }
      .app-faq-item-content {
        line-height: 1.5;
        font-size: 0.9rem;
        padding: 10px 25px 25px 25px
      }
    }
  }
}