.livewebcam-iframe {
    img {
        border-radius: 8px;
    }
}
.webcamp-weather {
    width: calc(100% + 40px);
    position: relative;
    left: -30px;
    padding-left: 10px;
}