@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-BookOblique.woff2') format('woff2'),
      url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-BookOblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-Book.woff2') format('woff2'),
      url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-BoldObl.woff2') format('woff2'),
      url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-BoldObl.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-Bold.woff2') format('woff2'),
      url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-Demi.woff2') format('woff2'),
      url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-Demi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-DemiOblique.woff2') format('woff2'),
      url('../../assets/fonts/ITCAvantGardeStd/AvantGarde-DemiOblique.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Black.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-ExtraBoldItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-ExtraLight.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-BlackItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Bold.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-BoldItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-ExtraBold.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Regular.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Italic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-ExtraLightItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-LightItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-MediumItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Medium.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Light.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-SemiBoldItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-ThinItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config Text';
  src: url('../../assets/fonts/Config/Config-Text.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Text.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config Text';
  src: url('../../assets/fonts/Config/Config-TextItalic.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-TextItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-SemiBold.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Config';
  src: url('../../assets/fonts/Config/Config-Thin.woff2') format('woff2'),
      url('../../assets/fonts/Config/Config-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Moranga';
  src: url('../../assets/fonts/Moranga/Moranga-Black.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-Black.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga Bold It';
  src: url('../../assets/fonts/Moranga/Moranga-BoldIt.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-BoldIt.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga Black It';
  src: url('../../assets/fonts/Moranga/Moranga-BlackIt.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-BlackIt.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga';
  src: url('../../assets/fonts/Moranga/Moranga-Light.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-Light.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga';
  src: url('../../assets/fonts/Moranga/Moranga-Bold.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-Bold.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga';
  src: url('../../assets/fonts/Moranga/Moranga-Regular.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-Regular.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga It';
  src: url('../../assets/fonts/Moranga/Moranga-RegularIt.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-RegularIt.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga Medium It';
  src: url('../../assets/fonts/Moranga/Moranga-MediumIt.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-MediumIt.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-MediumIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga';
  src: url('../../assets/fonts/Moranga/Moranga-Medium.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-Medium.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moranga Light It';
  src: url('../../assets/fonts/Moranga/Moranga-LightIt.woff2') format('woff2'),
      url('../../assets/fonts/Moranga/Moranga-LightIt.woff') format('woff'),
      url('../../assets/fonts/Moranga/Moranga-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $font-family-heading;
}