// Sass Utils
@import "sass-utilis/mixins";
@import "sass-utilis/functions";
@import "sass-utilis/helpers";
@import "sass-utilis/variables";

// Base
@import "base/reset";
@import "base/typography";
@import "base/base";

// Layout
@import "layout/base";
@import "layout/header";
@import "layout/navigation";
@import "layout/grid";

// Component
@import "components/buttons";
@import "components/forms";
@import "components/headline";
@import "components/block";
@import "components/card-page-header";
@import "components/card-weather";
@import "components/card-room";
@import "components/protected_pages";
@import "components/services";

// Pages
@import "pages/table-booking";
@import "pages/webcam";
@import "pages/login";
@import "pages/reservation";
@import "pages/notification";
@import "pages/single-room";
@import "pages/restaurant-menu";
@import "pages/blog";
@import "pages/faq";

ion-app {
  background: $grey-light;
}
ion-page {
  background-color: $grey-light;
  font-family: $font-family-main;
}
ion-content::part(background) {
  background-color: $grey-light;
}
ion-item::part(native) {
  background: $grey-light;
}

.app-side-nav-logo {
  text-align: center;
  padding: 20px 15px;
  svg {
    width: 180px;
  }
}


ion-item {
  ion-label {
    font-family: $font-family-main !important;
    font-size: 1.2rem !important;
    font-weight: 500;
  }
  ion-note {
    font-size: 0.9rem !important;
    font-weight: 600;
    font-family: $font-family-main;
    letter-spacing: 2px;
    color: $primary;
    text-decoration: underline;
    text-transform: uppercase;
  }
}

.table-reservation {
  ion-card {
    padding: 0;
    box-shadow: none;
    border: 1px solid $grey-light-plus;
    margin-top: 0;
  }
  .form-title {
    font-weight: 600;
  }
  .form-item {
    &-card {
      &-row {
        padding: 15px 5px;
        border-bottom: 1px solid $grey-light-plus;
        &:last-child {
          border-bottom: 0;
        }
        &.no-bd {
          border: 0;
        }
        ion-item::part(native) {
          background: transparent;
          border: 0 !important;
          --border-width: 0;
          .item-inner {
            border: 0;
          }
        }
        .item-inner {
          border: 0;
        }
        &-form {
          padding: 15px 18px;
        }
      }
      &-label {
        padding: 0 12px;
        height: 100%;
        display: flex;
        align-items: center;
        color: $carbon-grey;
        label {
          font-weight: 500;
          color: $carbon-grey !important;
          font-size: 1.1rem;
        }
      }
      &-qty {
        display: flex;
        justify-content: flex-end;
        border: 1px solid $grey-light-plus;
        padding: 15px;
        border-radius: 8px;
        margin-right: 12px;
        font-weight: 500;
        font-size: 1.3rem;
        color: #000 !important;
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          font-family: $font-family-main;
        }
      }
      &-select {
        border: 1px solid $grey-light-plus;
        border-radius: 8px;
        color: #000 !important;
        display: flex;
        margin-left: auto;
        padding: 8px;
      }
      &-time-row {
        margin: 0 18px;
        padding-bottom: 0;
      }
      &-time {
        padding: 9px 10px;
        border: 1px solid $grey-light-plus;
        border-radius: 6px;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 500;
        color: $black-light;
        background-color: transparent;
        width: 100%;
        &.active {
          background-color: $primary;
          color: $white;
          border-color: $primary;
        }
      }
      &-slider-day {
        z-index: 9;
        position: relative;
        padding-bottom: 20px;
      }
      &-day {
        padding: 15px 10px;
        background-color: transparent;
        border-radius: 8px;
        margin-top: 20px;
        margin-top: 0;
        width: 100%;
        &__inner {
          display: flex;
          flex-direction: column;
          text-transform: capitalize;
          font-weight: 500;
          font-family: $font-family-main;
          line-height: 1.4;
          border-radius: 8px;
          .weekday {
          }
          .data {
            font-size: 1.4rem;
            font-weight: 600;
          }
        }
        &.form-item-card-day-selected {
          background-color: $primary;
          color: $white;
          border-radius: 12px;
        }
      }
    }
  }
}

.form-item-card-day::part(native) {
  background-color: transparent;
  color: $black-light;
}

ion-content {
  &.app-page-wrapper {
    padding-top: 80px;
  }
}
.form-item ion-item::part(native) {
  background: transparent;
  border: 0 !important;
  .item-inner {
    border: 0;
  }
}

ion-select::part(text) {
  font-family: $font-family-main;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: -15px;
}

ion-footer {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  &.footer-md{
    &::before {
      content: none;
    }
  }
  ion-toolbar {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: 0px 0px 9px 2px rgb(0 0 0 / 10%) !important;
    padding: 10px 20px;
    .toolbar-background {
      background-color: transparent;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      border: 0;
    }
  }
  &.app-footer {
    .app-footer-label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      font-family: $font-family-heading;
    }
  }
}
ion-footer.app-footer .toolbar-background {
  background: transparent !important;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.weather-background {
  --padding-top: 100px;
  --background: url("../assets/images/hero-home-evening.jpg");
}
