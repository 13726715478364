.reservation-card {
    padding: 20px;
    background: $white;
    margin-bottom: 20px;
    border: 1px solid $grey-light-plus;
    border-radius: 4px;
    &:last-child {
        margin-bottom: 0;
    }
    &-title {
        font-weight: 600;
        font-size: 1.3rem;
        font-family: $font-family-heading;
    }
    &-people {
        font-size: 1.2rem;
        font-weight: 500;
    }
    .app-badge {
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
        display: inline-flex;
        border-radius: 4px;
        color: $white;
        padding: 4px 8px 8px;
        font-size: 0.8rem;
        &.app-badge-accepted {
            background: $primary;
        }
        &.app-badge-rejected {
            background: $red;
        }
        &.app-badge-requested {
            background: $secondary;
        }
    }
}

.reservation-details {
    .reservation-details-label {
        font-weight: 600;
        color: $grey;
        p {
            margin-bottom: 7px;
        }
    }
    .reservation-details-block {
        padding: 20px;
        background: #ffffff;
        margin-bottom: 25px;
        border: 1px solid #dedede;
        border-radius: 4px;
        h3 {
            margin-top: 5px;
        }
        h6 {
            font-size: 1.2rem;
            font-weight: 500;
            font-family: $font-family-main;
            margin-bottom: 3px;
            margin-top: 5px;
        }
        p {
            margin-bottom: 0;
        }
    }
}

.reservation-message {
    .reservation-message-label {
        font-weight: 600;
        color: $grey;
        p {
            margin-bottom: 7px;
        }
    }
    .reservation-message-block {
        padding: 20px;
        background: $white;
        margin-bottom: 20px;
        border: 1px solid $grey-light-plus;
        border-radius: 4px;
        &-list {
            margin-top: 20px;
            &-item {
                position: relative;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                &-header {
                    display: flex;
                    flex-wrap: wrap;
                }
                &-date {
                    max-width: 70%;
                    flex: 0 0 70%;
                    font-weight: 600;
                    color: $carbon-grey;
                }
                &-user {
                    max-width: 30%;
                    flex: 0 0 30%;
                    display: flex;
                    justify-content: flex-end;
                    font-weight: 500;
                    font-style: italic;
                    color: $grey;
                }
                &-content {
                    background: #f5f5f5;
                    padding: 20px;
                    font-style: italic;
                    border-radius: 4px;
                    margin-top: 5px;
                }
            }
        }
    }
}

.app-modal {
    .app-modal-header {
        text-align: center;
        background-color: $white;
        border-bottom: 1px solid $grey-light-plus;
    }
    .app-modal-content {
        .app-modal-body {
            padding: 100px 25px 50px;
        }
        .form-wrapper {
            .form-item {
                margin-bottom: 35px;
            }
            .form-item-title {
                text-align: center;
                margin-bottom: 15px;
                h4 {
                    margin-top: 0;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 1.1rem;
                    margin:0
                }
            }
            .form-item-card {
                background-color: $white;
                border-radius: 6px;
                padding: 15px;
                overflow: hidden;
                border: 1px solid $grey-light-plus;
                ion-datetime {
                    background-color: transparent;
                }
            }
        }
    }
    .app-modal-footer {
        .app-modal-footer-submit {
            padding-top: 10px;
            display: flex;
            align-items: center;
            max-width: 80%;
            margin: 0 auto;
        }
        ion-toolbar {
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            box-shadow: 0px 0px 9px 2px rgb(0 0 0 / 10%) !important;
            padding: 10px 20px;
            background-color: $white;
        }
        .toolbar-background {
            background-color: $white;
        }
    }
}

.app-block-skeleton {
    height: 50px;
    border-radius: 6px;
}

.app-reservation-filter {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .label {
        font-weight: 500;
        margin-bottom: 5px;
    }
    .app-reservation-filter-buttons {
        display: flex;
        gap: 10px;
        .app-reservation-filter-item {
            background: #fff;
            border: 1px solid #dedede;
            border-radius: 4px;
            padding: 5px 9px;
            font-weight: 500;
            &.active {
                border-color: $primary;
                background-color: $primary;
                color: $white;
                &.all {
                    border-color: $primary;
                }
                &.rejected {
                    border-color: $red;
                }
                &.accepted {
                    border-color: $primary;
                }
                &.requested {
                    border-color: $secondary;
                }
            }
        }
    }
}

.app-modal-reservation {
    .reservation-form {

    }
}

.app-modal-reservation {
    .form-title {
      font-weight: 600;
    }
    .form-item {
        .form-item-card {
            &-row {
                padding: 0;
                border-bottom: 1px solid $grey-light-plus;
                &:last-child {
                    border-bottom: 0;
                }
                &.no-bd {
                    border: 0;
                }
            ion-item::part(native) {
                background: transparent;
                border: 0 !important;
                --border-width: 0;
                .item-inner {
                border: 0;
                }
            }
            .item-inner {
                border: 0;
            }
            &-form {
                padding: 15px 18px;
            }
            }
            &-label {
                padding: 0 12px 0 0;
                height: 100%;
                display: flex;
                align-items: center;
                color: $carbon-grey;
                text-align: center;
                margin-bottom: 15px;
                justify-content: center;
                font-weight: 500;
                &.no-pr {
                    padding-right: 0;
                }
                &.no-mb {
                    margin-bottom: 0;
                }
                label {
                    font-weight: 500;
                    color: $carbon-grey !important;
                    font-size: 1.1rem;
                }
            }
            &-qty {
            display: flex;
            justify-content: flex-end;
            border: 1px solid $grey-light-plus;
            padding: 15px;
            border-radius: 8px;
            margin-right: 12px;
            font-weight: 500;
            font-size: 1.3rem;
            color: #000 !important;
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                font-family: $font-family-main;
            }
            }
            &-select {
            border: 1px solid $grey-light-plus;
            border-radius: 8px;
            color: #000 !important;
            display: flex;
            margin-left: auto;
            padding: 8px;
            }
            &-time-row {
                margin: 0;
                padding-bottom: 0;
            }
            &-time {
                padding: 9px 10px;
                border: 1px solid $grey-light-plus;
                border-radius: 6px;
                text-align: center;
                font-size: 1.2rem;
                font-weight: 500;
                color: $black-light;
                background-color: transparent;
                width: 100%;
                &.active {
                    background-color: $primary;
                    color: $white;
                    border-color: $primary;
                }
            }
            &-slider-day {
                z-index: 9;
                position: relative;
                padding-bottom: 10px;
                width: calc(100% + 40px);
                left: -20px;
                padding-top: 15px;
            }
            &-day {
                padding: 15px 10px;
                background-color: transparent;
                border-radius: 8px;
                margin-top: 20px;
                margin-top: 0;
                width: 100%;
            &__inner {
                display: flex;
                flex-direction: column;
                text-transform: capitalize;
                font-weight: 500;
                font-family: $font-family-main;
                line-height: 1.4;
                border-radius: 8px;
                .weekday {
                }
                .data {
                    font-size: 1.4rem;
                    font-weight: 600;
                }
            }
            &.form-item-card-day-selected {
                background-color: $primary;
                color: $white;
                border-radius: 12px;
            }
        }
      }
    }
  }