label,
input,
select,
textarea {
  font-family: $font-family-main;
  line-height: normal;
}

.form-wrapper {
  .form-item-card {
    &.form-item-card-skeleton {
      padding: 15px 10px;
    }
  }
  .form-input {
    margin-bottom: 6px;
    position: relative;
    label {
      display: block;
      font-weight: 500;
      color: $black-light;
      font-size: 1rem;
      margin-bottom: 7px;
    }
    ion-input {
      border: 1px solid $grey-light-plus;
      border-radius: 4px;
      margin-top: 0;
      padding: 0 !important;
      input {
        height: 60px;
        padding: 10px 10px !important;
      }
    }
    ion-textarea {
      border: 1px solid $grey-light-plus;
      border-radius: 4px;
      margin-top: 0px;
      padding: 0 !important;
      textarea {
        height: 120px;
        padding: 10px 15px;
        background-color: $white;
        border-radius: 4px;
      }
    }
    .form-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      z-index: 2;
      ion-icon {
        font-size: 1.6rem;
      }
    }
    &.form-checkbox {
      display: flex;
      gap: 8px;
      ion-label {
        line-height: 1;
        margin-top: 5px;
      }
      ion-checkbox {
        width: 25px;
        min-width: 25px;
        height: 25px;
      }
    }
  }
  &-input-white {
    .form-input {
      ion-input {
        background-color: $white;
      }
    }
  }
  .form-item {
    &-link {
      margin-top: 5px;
      a {
        color: $primary;
        font-style: italic;
        text-decoration: none;
        font-weight: 500;
      }
    }
    &-align-right {
      text-align: right;
    }
    &-submit {
      margin-top: 25px;
    }
    &-inline {
      button {
        height: 40px;
        padding: 5px;
        svg {
          width: 15px;
        }
      }
    }
  }
}
:host .calendar-day.calendar-day-active {
  color: $primary;
  font-weight: 600;
}