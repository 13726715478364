.app-notification-item {
    padding: 20px;
    background: $white;
    margin-bottom: 20px;
    border: 1px solid $grey-light-plus;
    border-radius: 4px;
    .app-notification-item-wrapper {
        display: flex;
        gap: 10px;
    }
    &-icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        background-color: #f5f5f5;
        border-radius: 100%;
    }
    p {
        margin: 0;
    }
    .title {
        font-weight: 600;
        font-size: 1.3rem;
        margin-bottom: 5px;
    }
}