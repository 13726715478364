.main-app-weather-slider {
    margin-top: -20px;
    max-height: 290px;
    padding-left: 15px;
    .main-app-weather-slider-container {
        .slider-weather {
            width: calc(100% + 30px);
            padding-left: 15px;
            position: relative;
            left: -15px;
        }
    }
  }
  .main-app-weather {
    .main-app-section-title {
        padding-top: 20px;
    }
    &-item {
        &.active {
            background: rgb(44,62,80);
            background: linear-gradient(0deg, rgba(44,62,80,1) 40%, rgba(41,128,185,1) 100%);
        }
    }
}
  
.card-weather {
    border: 2px solid $grey-light-plus;
    box-shadow: none;
    border-radius: 20px;
    margin: 30px 3px;
    &.active {
        box-shadow: 0px 0px 7px 10px rgb(44, 62, 80, 0.15);
        border-color: #2c3e50;
        background: rgb(44,62,80);
        background: linear-gradient(0deg, rgba(44,62,80,1) 40%, rgba(41,128,185,1) 100%);
    }
    ion-card-content {
        padding: 20px 5px;
    }
    &-content {
      text-align: center;
      max-height: 290px;
        ion-img {
            width: 50px;
            margin: 0 auto;
        }
        svg{
            max-height: 80px;
        }
        &-label {
            font-family: $font-family-heading;
            font-size: 1.1rem;
            color: #161615;
            font-weight: 600;
            .card-weather.active & {
                color: $white;
            }
        }
    }
}